import axios from '../libs/ajaxRequest';
import zzAxios from '../libs/zzAjaxRequest';

/* 获取登录用户信息 */
const getauthinfo = () => {
    return axios.request({
        url: 'auth/user/info',
        method: 'get'
    });
};

/* 更新用户头像和昵称 */
const editInfo = (data) => {
    return axios.request({
        url: 'auth/user/edit',
        method: 'post',
        data
    });
};

/* 获取客户收货地址列表 */
const getAddress = () => {
    return axios.request({
        url: 'auth/address/list',
        method: 'get'
    });
};

/* 新增客户收货地址 */
const addAddress = (data) => {
    return axios.request({
        url: 'auth/address/add',
        method: 'post',
        data
    });
};

/* 编辑客户收货地址 */
const editAddress = (data) => {
    return axios.request({
        url: 'auth/address/edit',
        method: 'post',
        data
    });
};

/* 获取订单列表 */
const getOrderList = (data) => {
    return axios.request({
        url: `order/list/${data.index}?key=${data.key}&status=${data.status}`,
        method: 'get'
    });
};

/* 获取订单详情 */
const orderDetails = (data) => {
    return axios.request({
        url: `order/detail/${data.orderId}`,
        method: 'get'
    });
};

/* 上传文件 */
const upload = (data) => {
    return zzAxios.request({
        url: 'home/upbase64common',
        method: 'post',
        data
    });
};

/* 取消确认订单 */
const update = (data) => {
    return axios.request({
        url: 'order/update',
        method: 'post',
        data, 
    });
};

/* 获取积分 */
const getIntergal = (data) => {
    return zzAxios.request({
        url: `https://www.hnliangzhu.top/lzshop/score/getscore?uid=${data.uid}`,
        method: 'get',
    });
};



export {
    getauthinfo,
    editInfo,
    getAddress,
    addAddress,
    editAddress,
    getOrderList,
    orderDetails,
    upload,
    update,
    getIntergal
}