<template>
    <div id="logistics">
        <div class="navbar">
            <button @click="returnPage"><i class="iconfont icon-fanhui1"></i>物流信息</button>
        </div>

        <div class="content" v-if="details">
            <div class="card" v-for="(item, index) in details.products" :key="index" @click="jump('productdetails', item.id)">
                <img :src="item.listImg"/>
                <div>
                    <div>
                        <h4>{{item.name}}</h4>
                        <p>{{item.specification}}</p>
                    </div>
                    <p>￥<span>{{fixedPrice(item.cashPrice)}} </span>+<span> {{item.integral}}</span> 良豆</p>
                </div>
            </div>

            <div class="address">
                <p>{{details.regionName}}</p>
                <p>{{details.address}}</p>
                <p>{{details.shipTo}} {{details.cellPhone}}</p>
            </div>

            <div class="info" v-if="details.orderStatus == 1">
                <div class="title">
                    <div>
                        <h4>等待付款</h4>
                        <h4>总价：{{`${fixedPrice(details.totalPrice)} + ${details.totalIntergral}良豆`}}</h4>
                        <h4>运费：{{details.freight}}元</h4>
                        <h4>订单编号：{{details.orderId}}</h4>
                        <p>如有疑问请联系商家</p>
                    </div>
                    <i class="iconfont icon-lianxikefu3x"></i>
                </div>
                <div class="step">
                    <van-steps direction="vertical" :active="details.logs.length" active-color="#32aaff">
                        <van-step v-for="(item, index) in details.logs" :key="index"> 
                            <h3>{{item.content}}</h3>
                            <p>{{item.time}}</p>
                        </van-step>
                    </van-steps>
                </div>
            </div>

            <div class="info" v-if="details.orderStatus == 2">
                <div class="title">
                    <div>
                        <h4>等待商家发货</h4>
                        <h4>总价：{{`${fixedPrice(details.totalPrice)} + ${details.totalIntergral}良豆`}}</h4>
                        <h4>运费：{{details.freight}}元</h4>
                        <h4>订单编号：{{details.orderId}}</h4>
                        <p>如有疑问请联系商家</p>
                    </div>
                    <i class="iconfont icon-lianxikefu3x"></i>
                </div>
                <div class="step">
                    <van-steps direction="vertical" :active="details.logs.length" active-color="#32aaff">
                        <van-step v-for="(item, index) in details.logs" :key="index"> 
                            <h3>{{item.content}}</h3>
                            <p>{{item.time}}</p>
                        </van-step>
                    </van-steps>
                </div>
            </div>

            <div class="info" v-if="details.orderStatus == 3">
                <div class="title">
                    <div>
                        <h4>商品已发货</h4>
                        <h4>总价：{{`${fixedPrice(details.totalPrice)} + ${details.totalIntergral}良豆`}}</h4>
                        <h4>运费：{{details.freight}}元</h4>
                        <h4>订单编号：{{details.orderId}}</h4>
                        <h4>物流公司：{{details.expressCompanyName}}</h4>
                        <h4 
                        v-clipboard:copy="details.shipOrderNumber"
                        v-clipboard:success="firstCopySuccess"
                        v-clipboard:error="firstCopyError">物流单号：{{details.shipOrderNumber}} <i class="iconfont icon-fuzhi"></i></h4>
                        <p>如有疑问请联系商家</p>
                    </div>
                    <i class="iconfont icon-lianxikefu3x"></i>
                </div>
                <div class="step">
                    <van-steps direction="vertical" :active="details.logs.length" active-color="#32aaff">
                        <van-step v-for="(item, index) in details.logs" :key="index"> 
                            <h3>{{item.content}}</h3>
                            <p>{{date(item.time)}}</p>
                        </van-step>
                    </van-steps>
                </div>
            </div>

             <div class="info" v-if="details.orderStatus == 4">
                <div class="title">
                    <div>
                        <h4>订单已取消</h4>
                        <h4>总价：{{`${fixedPrice(details.totalPrice)} + ${details.totalIntergral}良豆`}}</h4>
                        <h4>运费：{{details.freight}}元</h4>
                        <h4>订单编号：{{details.orderId}}</h4>
                        <p>如有疑问请联系商家</p>
                    </div>
                    <i class="iconfont icon-lianxikefu3x"></i>
                </div>
            </div>

            <div class="info" v-if="details.orderStatus == 5">
                <div class="title">
                    <div>
                        <h4>商品已签收</h4>
                        <h4>总价：{{`${fixedPrice(details.totalPrice)} + ${details.totalIntergral}良豆`}}</h4>
                        <h4>运费：{{details.freight}}元</h4>
                        <h4>订单编号：{{details.orderId}}</h4>
                        <h4>物流公司：{{details.expressCompanyName}}</h4>
                        <h4 
                        v-clipboard:copy="details.shipOrderNumber"
                        v-clipboard:success="firstCopySuccess"
                        v-clipboard:error="firstCopyError">物流单号：{{details.shipOrderNumber}} <i class="iconfont icon-fuzhi"></i></h4>
                        <p>如有疑问请联系商家</p>
                    </div>
                    <i class="iconfont icon-lianxikefu3x"></i>
                </div>
                <div class="step">
                    <van-steps direction="vertical" :active="details.logs.length" active-color="#32aaff">
                        <van-step v-for="(item, index) in details.logs" :key="index"> 
                            <h3>{{item.content}}</h3>
                            <p>{{item.time}}</p>
                        </van-step>
                    </van-steps>
                </div>
            </div>

            <div class="detailNavBar" v-if="details.orderStatus == 1">
                <button @click="cancelOrder(details.orderId)">取消订单</button>
                <button @click.stop="patment(details.orderId)">立即支付</button>
            </div>

            <div class="detailNavBar" v-if="details.orderStatus == 2">
                <button>申请退款</button>
                <button>提醒发货</button>
            </div>

            <div class="detailNavBar" v-if="details.orderStatus == 3">
                <button style="display:none">申请退款</button>
                <button @click="confirmReceipt(details.orderId)">确认收货</button>
            </div>

            <div class="detailNavBar" v-if="details.orderStatus == 5">
                <button>再来一单</button>
            </div>

            <van-popup v-model="h5Show" class="h5Show" :close-on-click-overlay="false">
                <div>请确认微信支付是否完成</div>
                    <p @click="h5Jump(1)">已完成支付</p>
                    <p @click="h5Jump(2)">取消支付</p>
                </van-popup>
           
        </div>
    </div>
</template>

<script>
import {orderDetails, update} from '../../api/user'
import {getPayInfo, h5pay} from '../../api/wxjsapi'
export default {
    data () {
        return {
            details: '',
            h5Show: false
        }
    },
    mounted () {
        this.getOrderDetails();
    },
    computed: {
        fixedPrice () {
            return (price) => {
                return price.toFixed(2);
            }
        },

        date () {
            return (time) => {
                return time.replace('T', ' ');
            }
        }
    },
    methods: {
        /* 订单详情 */
        async getOrderDetails () {
            this.$toast.loading({
                duration: 0,
                forbidClick: true
            })
            const {orderId} = this.$route.query;
            let res = await orderDetails({orderId});
            this.$toast.clear();
            this.details = res.data;
            console.log(this.details)
        },
        /* 返回上一步页面 */
        returnPage () {
            this.$router.go(-1);
        },
        /* 复制物流单号 */
        copyOrderNum (num) {
            document.execCommand(num);
        },

        firstCopySuccess(e) {
            // console.log("copy arguments e:", e);
            this.$toast("复制成功!");
        },
        firstCopyError(e) {
            // console.log("copy arguments e:", e);
            this.$toast("复制失败!");
        },

        /* 页面跳转 */
        jump (name, productId) {
            const {$router, $route} = this;
            const {appKey, credits, timestamp, sign, uid, code, state, integral} = $route.query;
            $router.push({name, query:{appKey, credits, timestamp, sign, uid, productId, code, state, integral}});
        },

        /* 付款 */
        async patment (orderId) {
            let us = window.navigator.userAgent;
            if (us.indexOf('MicroMessenger') > -1) { //微信支付
                let arr = {
                    openId: localStorage.getItem('openid'),
                    orderId
                }
                let res = await getPayInfo(arr);
                console.log(res);
                const {data} = res;
                this.onBridgeReady(data);
            } else { //h5支付
                // this.$toast('h5支付');
                this.h5Payment(orderId);
            }
        },

        /* h5Payment */
        async h5Payment (orderId) {
            let res = await h5pay({orderId});
            const {code, data, msg} = res;
            if (code === 1) {
                this.h5Show = true;
                window.location.href = data;
            }
        },

        h5Jump (status) {
            if (status == 1) {
                this.getOrderDetails();
            }
            this.h5Show = false;
        },

        onBridgeReady(res) {
            let _this = this;
            // const {appId, timeStamp, nonceStr, package, signType, paySign} = res;
            WeixinJSBridge.invoke('getBrandWCPayRequest', {
                appId: res.appId,
                //公众号名称，由商户传入     
                timeStamp: res.timeStamp,
                //时间戳，自1970年以来的秒数     
                nonceStr: res.nonceStr,
                //随机串     
                package:  res.package,
                signType:  res.signType,
                //微信签名方式：     
                paySign: res.paySign, //微信签名 
            },
            function(res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    this.getOrderDetails();
                }
            });
        },

        

        /* 取消订单 */
        async cancelOrder (orderId) {
            let updateData = {
                orderId,
                action: 'cancel'
            }
            let res = await update(updateData);
            const {code, msg} = res;
            if (code == 1) {
                this.$toast('订单已取消');
                this.getOrderDetails();
            } else {
                this.$toast(msg);
            }
        },

        /* 确认收货 */
        async confirmReceipt (orderId) {
            let updateData = {
                orderId,
                action: 'confirm'
            }
            let res = await update(updateData);
            const {code, msg} = res;
            if (code == 1) {
                this.$toast('已确认收货');
                this.getOrderDetails();
            } else {
                this.$toast(msg);
            }
        },

    }
}
</script>

<style lang="less" src="@/style/user/logistics.less">

</style>