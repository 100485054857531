import axios from 'axios';

const instance = axios.create({
    // baseURL: 'https://api-admin.liangzhukeji.com/api/'
    // baseURL: 'https://api.zzliangzhu.top/api/'
    baseURL: process.env.VUE_APP_ZZ_BASE_URL
});

instance.interceptors.request.use(config => {
    return config;
});

instance.interceptors.response.use(res => {
    return res.data;
});

export default instance;